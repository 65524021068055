import axios from "axios";
import { RiUser3Line } from "react-icons/ri";
import { update } from "../../features/auth/authSlice";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const UserProfile = () => {
  const { userAndToken: userData } = useSelector((state) => state.auth);

  const inputRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [profileData, setProfileData] = useState({
    id: userData?.user?.id,
    name: userData?.user?.name,
    email: userData?.user?.email,
    phone: userData?.user?.phone,
    address: userData?.user?.address,
    country: userData?.user?.country,
  });

  const dispatch = useDispatch();

  const [picture, setPicture] = useState(null);

  const handleImage = (e) => {
    setPicture({ image: e.target.files[0] });
  };

  const handleUpdate = useCallback(
    async (e) => {
      e.preventDefault();

      setLoading(true);

      const formData = new FormData();
      formData.append("id", profileData.id);
      picture && formData.append("avatar", picture?.image);
      formData.append("name", profileData.name);
      formData.append("email", profileData.email);
      formData.append("phone", profileData.phone);
      formData.append("address", profileData.address);
      formData.append("country", profileData.country);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URL}/api/UserUpdate`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (res.data.status === 200) {
          dispatch(update(res.data.userinfo));
          setLoading(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    [
      profileData?.id,
      profileData?.email,
      profileData?.phone,
      profileData?.address,
      profileData?.name,
      profileData?.country,
      picture,

      dispatch,
    ]
  );
  return (
    <div className="p-5 flex flex-col ">
      <span className="flex gap-1 text-2xl items-center font-semibold">
        <RiUser3Line />
        <h2>Profile</h2>
      </span>
      <form onSubmit={handleUpdate}>
        {/* user images */}
        <div className="form-control flex flex-col  mt-2">
          <label className="text-lg  text-gray-600">Your Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImage}
            ref={inputRef}
            name="image"
            className={`form-control py-2  `}
          />
        </div>
        {/* user name */}
        <div className="form-control flex flex-col gap-2 mt-2">
          <label className="">Your Name</label>
          <input
            type="text"
            onChange={(e) =>
              setProfileData({ ...profileData, name: e.target.value })
            }
            value={profileData?.name}
            className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
            placeholder="Enter Your Name"
          />
        </div>
        {/* user email */}
        <div className="form-control flex flex-col gap-2 mt-2">
          <label className="">Your Email</label>
          <input
            type="email"
            // onChange={(e) =>
            //   setFormData({ ...formData, email: e.target.value })
            // }
            value={profileData?.email}
            readOnly
            className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
            placeholder="Enter Your Email"
          />
        </div>
        {/* user address */}
        <div className="form-control flex flex-col gap-2 mt-2">
          <label className="">Your Address</label>
          <input
            type="text"
            onChange={(e) =>
              setProfileData({ ...profileData, address: e.target.value })
            }
            value={profileData?.address}
            className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
            placeholder="Enter Your Address"
          />
        </div>
        {/* user phone */}
        <div className="form-control flex flex-col gap-2 mt-2">
          <label className="">Your Number</label>
          <input
            type="number"
            onChange={(e) =>
              setProfileData({ ...profileData, phone: e.target.value })
            }
            value={profileData?.phone}
            className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
            placeholder="Enter Your Number"
          />
        </div>

        {/* your country */}
        <div className="form-control flex flex-col gap-2 mt-2">
          <label className="">Your country</label>
          <input
            type="text"
            onChange={(e) =>
              setProfileData({ ...profileData, country: e.target.value })
            }
            value={profileData?.country}
            className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
            placeholder="Enter Your Country"
          />
        </div>

        <div className="flex items-center justify-center my-10">
          {!loading ? (
            <button
              type="submit"
              className="bg-black hover:bg-[#3A3A3A] py-3 px-8  rounded-md  text-white duration-300"
            >
              Update
            </button>
          ) : (
            <button
              disabled
              className="bg-black  py-3 px-8  rounded-md  text-white duration-300"
            >
              Updating your data ....
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserProfile;
