import { ToastContainer } from "react-toastify";

import { useSelector } from "react-redux";

import { useState } from "react";

import Navbar from "../Navbar";
import Footer from "../Footer";
import CartButton from "../CardButton";
import ScrollToTop from "../ScrollToTop";
import MobileNav from "../MobileNav";
import AddToCartCom from "../AddToCartCom";
import User from "../../pages/user/User";
const UserLayout = () => {
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  const [state, setState] = useState(false);
  const handleAddToCart = () => {
    setState(!state);
  };
  return (
    <>
      <div className="min-h-screen bg-[#F3F4F6] relative ">
        <Navbar />
        <ToastContainer />
        <ScrollToTop>
          <User />
        </ScrollToTop>

        <button
          onClick={handleAddToCart}
          className="fixed top-[45%] right-0 z-[9999]"
        >
          <CartButton data={data} cartTotalAmount={cartTotalAmount} />
        </button>
        {state && <AddToCartCom state={state} setState={setState} />}
        <div className="fixed z-[99999] bottom-0  md:hidden block w-full ">
          <MobileNav />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserLayout;
