import moment from "moment";
import React, { useEffect, useState } from "react";
import { CiGrid41 } from "react-icons/ci";
import { useSelector } from "react-redux";

const UserOrder = () => {
  const [order, setOrder] = useState([]);

  const { userAndToken: userData } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api/customer-invoice/${userData?.user?.email}`
        );
        const data = await res.json();
        setOrder(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [userData?.user?.email]);

  return (
    <div className="p-5 flex flex-col">
      <span className="flex gap-1 text-2xl items-center font-semibold">
        <CiGrid41 />
        <h2>My Order</h2>
      </span>

      {order?.length === 0 ? (
        <p className="mt-10 text-center">No Order Found</p>
      ) : (
        <div className="overflow-auto rounded-lg shadow mt-5 order-details-container hidden xl:block">
          <table className="w-full ">
            <thead className="bg-gray-50 border-b-2 border-gray-200 ">
              <tr>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Code
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Date
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Product Details
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Shipping Charge
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Amount
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Delivery Status
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Payment Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {order?.slice(0, 10).map((item) => (
                <tr key={item.id} className="bg-gray-50">
                  <td className="p-3 w-24 text-sm text-gray-700 whitespace-nowrap">
                    {item?.code}
                  </td>

                  <td className="p-3 w-24 text-sm text-gray-700 whitespace-nowrap">
                    {moment(item?.create_at).format("DD MMM YY. hh:mm A")}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <table className="w-full">
                      <thead className="bg-gray-50 border-b-2 border-gray-200 ">
                        <tr className="text-sm font-semibold tracking-wide text-left">
                          <th className="text-sm font-semibold tracking-wide text-left">
                            Product Name
                          </th>
                          <th className=" text-sm font-semibold tracking-wide text-left">
                            Quantity
                          </th>
                          <th className=" text-sm font-semibold tracking-wide text-left">
                            Unit Price
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {JSON.parse(item?.items).map((item) => (
                          <tr key={item.id}>
                            <td className=" text-sm text-gray-700 whitespace-nowrap">
                              {item.name}
                            </td>
                            <td className=" w-20 text-sm text-gray-700 whitespace-nowrap">
                              {item.cartQuantity}
                            </td>
                            <td className="w-14 text-sm text-gray-700 whitespace-nowrap">
                              ৳{+item.variantPrice}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    {+item?.shipping_cost}
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    ৳{+item?.grand_total}
                  </td>
                  <td className="p-3  w-20 text-sm text-gray-700 whitespace-nowrap">
                    <span
                      className={`p-1.5 text-xs font-medium uppercase tracking-wider ${
                        item?.delivery_status === "pending" &&
                        "text-yellow-800 bg-yellow-200"
                      }  ${
                        item?.delivery_status === "confirmed" &&
                        "text-green-800 bg-green-200"
                      }  ${
                        item?.delivery_status === "canceled" &&
                        "text-gray-100 bg-red-200"
                      }  rounded-lg bg-opacity-50`}
                    >
                      {item?.delivery_status}
                    </span>
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    <span
                      className={`p-1.5 text-xs font-medium uppercase tracking-wider ${
                        item?.payment_status === "pending" &&
                        "text-yellow-800 bg-yellow-200"
                      }  ${
                        item?.delivery_status === "paid" &&
                        "text-green-800 bg-green-200"
                      }  rounded-lg bg-opacity-50`}
                    >
                      {item?.payment_status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* {order?.length > 0 && (
        <div className="overflow-auto rounded-lg shadow mt-5 order-details-container hidden xl:block">
          <table className="w-full ">
            <thead className="bg-gray-50 border-b-2 border-gray-200 ">
              <tr>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Code
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Date
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Product Details
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Shipping Charge
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Amount
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Delivery Status
                </th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                  Payment Status
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {order?.map((item) => (
                <tr key={item.id} className="bg-gray-50">
                  <td className="p-3 w-24 text-sm text-gray-700 whitespace-nowrap">
                    {item?.code}
                  </td>

                  <td className="p-3 w-24 text-sm text-gray-700 whitespace-nowrap">
                    {moment(item?.create_at).format("DD MMM YY. hh:mm A")}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <table className="w-full">
                      <thead className="bg-gray-50 border-b-2 border-gray-200 ">
                        <tr className="text-sm font-semibold tracking-wide text-left">
                          <th className="text-sm font-semibold tracking-wide text-left">
                            Product Name
                          </th>
                          <th className=" text-sm font-semibold tracking-wide text-left">
                            Quantity
                          </th>
                          <th className=" text-sm font-semibold tracking-wide text-left">
                            Unit Price
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {JSON.parse(item?.items).map((item) => (
                          <tr key={item.id}>
                            <td className=" text-sm text-gray-700 whitespace-nowrap">
                              {item.name}
                            </td>
                            <td className=" w-20 text-sm text-gray-700 whitespace-nowrap">
                              {item.cartQuantity}
                            </td>
                            <td className="w-14 text-sm text-gray-700 whitespace-nowrap">
                              {currencyFormatter(+item.unit_price)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    {+item?.shipping_cost}
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    {currencyFormatter(+item?.grand_total)}
                  </td>
                  <td className="p-3  w-20 text-sm text-gray-700 whitespace-nowrap">
                    <span
                      className={`p-1.5 text-xs font-medium uppercase tracking-wider ${
                        item?.delivery_status === "pending" &&
                        "text-yellow-800 bg-yellow-200"
                      }  ${
                        item?.delivery_status === "confirmed" &&
                        "text-green-800 bg-green-200"
                      }  ${
                        item?.delivery_status === "canceled" &&
                        "text-gray-100 bg-red-200"
                      }  rounded-lg bg-opacity-50`}
                    >
                      {item?.delivery_status}
                    </span>
                  </td>
                  <td className="p-3 w-20 text-sm text-gray-700 whitespace-nowrap">
                    <span
                      className={`p-1.5 text-xs font-medium uppercase tracking-wider ${
                        item?.payment_status === "pending" &&
                        "text-yellow-800 bg-yellow-200"
                      }  ${
                        item?.delivery_status === "paid" &&
                        "text-green-800 bg-green-200"
                      }  rounded-lg bg-opacity-50`}
                    >
                      {item?.payment_status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )} */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 xl:hidden">
        {order?.slice(0, 10).map((item) => (
          <div
            key={item.id}
            className="bg-white space-y-3 p-4 rounded-lg shadow"
          >
            <div className="flex items-center space-x-2 text-sm">
              <div>
                <span className="text-blue-500 font-bold hover:underline">
                  {item.code}
                </span>
              </div>
              <div className="text-gray-500">
                {moment(item?.create_at).format("DD MMM YY. hh:mm A")}
              </div>
              <div>
                <span
                  className={`p-1.5 text-xs font-medium uppercase tracking-wider ${
                    item?.delivery_status === "pending" &&
                    "text-yellow-800 bg-yellow-200"
                  }  ${
                    item?.delivery_status === "confirmed" &&
                    "text-green-800 bg-green-200"
                  }  ${
                    item?.delivery_status === "canceled" &&
                    "text-gray-100 bg-red-200"
                  }  rounded-lg bg-opacity-50`}
                >
                  {item?.delivery_status}
                </span>
              </div>
            </div>
            <div className="text-sm text-gray-700">
              {JSON.parse(item?.items).map((item) => (
                <div key={item.id} className="flex flex-col border-b mt-2">
                  <h4>Product Name: {item.name}</h4>
                  <span>Quantity: {item.cartQuantity}</span>
                  <span>Unit Price: ৳{+item.variantPrice}</span>
                </div>
              ))}
            </div>
            <div className="text-sm font-medium text-black">
              Total Price: ৳{+item?.grand_total}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserOrder;
