import { IoIosEyeOff, IoIosEye } from "react-icons/io";

const FormInput = ({
  label,
  type,
  value,
  name,
  placeholder,
  onChange,
  onClick,
  eye,
  accept,
}) => {
  return (
    <div className="form-group flex w-full flex-col ">
      {label && (
        <label htmlFor="input-field" className="text-lg mb-2 text-gray-600">
          {label}
        </label>
      )}
      <div className=" flex w-full flex-col gap-2 relative">
        <input
          type={type}
          value={value}
          name={name}
          accept={accept}
          className={`form-control py-2 px-2 ${
            type === "file" ? "" : " border  border-gray-300 focus:outline-none"
          } rounded-md 
          bg-transparent`}
          placeholder={placeholder}
          onChange={onChange}
          required
        />
        {name === "password" &&
          (value ? (
            <span className="absolute right-5 cursor-pointer text-gray-500 top-3 ">
              {eye ? (
                <IoIosEye onClick={onClick} />
              ) : (
                <IoIosEyeOff onClick={onClick} />
              )}
            </span>
          ) : null)}
      </div>
    </div>
  );
};

export default FormInput;
