import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LandingNavbar = () => {
  const { items: logo } = useSelector((state) => state.logo);
  return (
    <div className="sticky  md:px-0 -top-[70px] md:top-0 md:left-0 shadow-md  md:right-0 z-[9999] bg-white">
      {/* middle nav */}
      <div className="bg-black pt-5 md:py-5">
        <div className="container mx-auto flex  items-center justify-center ">
          <Link
            to={"/"}
            className="brand  h-[3rem]  rounded-md overflow-hidden"
          >
            <img
              src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
              className="w-full h-full object-cover"
              alt=""
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingNavbar;
