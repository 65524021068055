import React from "react";
import LandingNavbar from "../landingpage/LandingNavbar";
import ScrollToTop from "../ScrollToTop";
import { Outlet } from "react-router-dom";

const LandingLayout = () => {
  return (
    <div className=" bg-[#F2F3F8] ">
      <div className="min-h-screen">
        <LandingNavbar />
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
      </div>
      {/* <LandingFooter /> */}
    </div>
  );
};

export default LandingLayout;
