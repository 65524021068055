import React from "react";
import { Link } from "react-router-dom";
import Card from "./Card";

import {
  A11y,
  Navigation,
  HashNavigation,
  Pagination,
  Scrollbar,
  Grid,
  Autoplay,
  Parallax,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/hash-navigation";
const CategoryProductSection = ({ combinedData }) => {
  return (
    <>
      {combinedData?.map((category) => (
        <section
          key={category?.id}
          className="product-section container mx-auto px-3 mb-10"
        >
          <div className="header-section  mt-10 mb-5  flex justify-between items-center">
            <h2 className=" title text-3xl ">{category?.name}</h2>

            <Link
              to={`/categorypage/${category?.id}`}
              className=" underline underline-offset-2 font-semibold md:text-xl text-lg"
            >
              View All
            </Link>
          </div>

          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              HashNavigation,
              Grid,
              Autoplay,
              Parallax,
            ]}
            spaceBetween={25}
            // slidesPerView={6}
            // navigation
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            hashNavigation
            scrollbar={{ draggable: true }}
            parallax={true}
            grabCursor={true}
            loop={true}
            // breakpoints={{
            //   0: {
            //     slidesPerView: 2,
            //     spaceBetween: 10,
            //   },

            //   425: {
            //     slidesPerView: 3,
            //     spaceBetween: 25,
            //   },
            //   768: {
            //     slidesPerView: 4,
            //     spaceBetween: 25,
            //   },
            //   1024: {
            //     slidesPerView: 5,
            //     spaceBetween: 25,
            //   },
            //   1440: {
            //     slidesPerView: 6,
            //     spaceBetween: 25,
            //   },
            // }}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },

              330: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 25,
              },

              1024: {
                slidesPerView: 5,
                spaceBetween: 25,
              },
              1440: {
                slidesPerView: 6,
                spaceBetween: 25,
              },
            }}
          >
            {category?.products?.slice(0, 8).map((product) => (
              <SwiperSlide key={product.id}>
                <Card key={product?.id} product={product} />
              </SwiperSlide>
            ))}

            {category?.products?.length === 0 && (
              <p className="text-center col-span-full">No Products Found</p>
            )}
          </Swiper>
        </section>
      ))}
    </>
  );
};

export default CategoryProductSection;
