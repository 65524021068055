import axios from "axios";
import { toast } from "react-toastify";

export const axiosPost = async (endpoint, data, token) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_URL}${endpoint}`,
      data,
      {
        headers: {
          "Content-Type": "appication/json",

          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.data) {
      return res.data;
    }
  } catch (error) {
    console.log(error);
    toast.error(error, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
