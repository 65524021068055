import React from "react";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ProductImageCarasol = ({ singleProduct, thumbImage }) => {
  return (
    <div className="text-white text-[20px] w-auto md:w-[30rem]  lg:w-[40rem] object-cover">
      <Carousel
        showIndicators={false}
        showStatus={false}
        thumbWidth={60}
        infiniteLoop={true}
        preventMovementUntilSwipeScrollTolerance={true}
        className="productCarousel"
      >
        <div className="w-full h-[25rem] overflow-hidden">
          <img
            src={singleProduct}
            alt="details"
            className="w-full h-full object-cover"
          />
        </div>
        {thumbImage &&
          thumbImage?.slice(0, 5).map((image) => (
            <div key={image?.id} className="w-full h-[25rem] overflow-hidden">
              <img
                key={image?.id}
                src={`${process.env.REACT_APP_URL}/uploads/product/${image?.icon}`}
                alt="lol"
                className="w-full h-full object-cover"
              />
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default ProductImageCarasol;
