import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { useSelector } from "react-redux";
import HomeLayout from "./components/layout/HomeLayout";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import AddToCart from "./pages/AddToCart";
import Checkout from "./pages/Checkout";
import NotFound from "./pages/NotFound";
import SuccessPage from "./pages/SuccessPage";
import TermsConditions from "./pages/TermsConditions";
import ReturnPolicy from "./pages/ReturnPolicy";
import SupportPolicy from "./pages/SupportPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import About from "./pages/About";
import Contact from "./pages/Contact";
import SellCondition from "./pages/SellCondition";

import BlogPage from "./pages/BlogPage";
import CategoryPage from "./pages/CategoryPage";

import LandingPage from "./pages/LandingPage";

import SubCategoryPage from "./pages/SubCategoryPage";
import "react-toastify/dist/ReactToastify.css";
import LandingLayout from "./components/layout/LandingLayout";
import UserLayout from "./components/layout/UserLayout";
import UserProfile from "./pages/user/UserProfile";
import UserOrder from "./pages/user/UserOrder";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
function App() {
  const { cartItems: data } = useSelector((state) => state.cart);
  const { userAndToken: userData } = useSelector((state) => state.auth);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/productdetails/:id",
          element: <ProductDetails />,
        },
        {
          path: "/addtocart",
          element: <AddToCart />,
        },
        {
          path: "/checkout",
          element: data.length > 0 ? <Checkout /> : <NotFound />,
        },
        {
          path: "/successpage",
          element: <SuccessPage />,
        },
        {
          path: "/terms",
          element: <TermsConditions />,
        },
        {
          path: "/returnpolicy",
          element: <ReturnPolicy />,
        },
        {
          path: "/supportpolicy",
          element: <SupportPolicy />,
        },
        {
          path: "/privacypolicy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/about-us/:id",
          element: <About />,
        },
        {
          path: "/contact-us/:id",
          element: <Contact />,
        },
        {
          path: "/sellcondition",
          element: <SellCondition />,
        },
        {
          path: "/blogs/:id",
          element: <BlogPage />,
        },
        {
          path: "/categorypage/:id",
          element: <CategoryPage />,
        },
        {
          path: "/subcategory/:id",
          element: <SubCategoryPage />,
        },
        {
          path: "*",
          element: <NotFound />,
        },
        {
          path: "/login",
          element: userData ? <Navigate to="/" /> : <Login />,
        },
        {
          path: "/register",
          element: userData ? <Navigate to="/" /> : <SignUp />,
        },
      ],
    },
    {
      path: "/landingpage",
      element: <LandingLayout />,
      children: [
        {
          path: "/landingpage/:id",
          element: <LandingPage />,
        },
      ],
    },
    {
      path: "/user",
      element: <UserLayout />,
      children: [
        {
          index: true,
          element: <Navigate to={`/user/profile`} replace />,
        },
        {
          path: `/user/profile`,
          element: userData ? <UserProfile /> : <Navigate to="/" />,
        },
        {
          path: "/user/order",
          element: userData ? <UserOrder /> : <Navigate to="/" />,
        },
      ],
    },
  ]);

  // const { items: footerData } = useSelector((state) => state.footerDetails);
  // const [loading, setLoading] = useState(false);

  // return (
  //   <>
  //
  //     <div className=" bg-[#F2F3F8] ">
  //       <div className="min-h-screen">
  //         <Navbar handleMenu={handleMenu} />
  //         <ToastContainer />
  //         <ScrollToTop>
  //           <Routes>
  //             <Route path={"/"} element={<Home />} />
  //             <Route path="/productdetails" element={<ProductDetails />} />
  //             <Route
  //               exact
  //               path="/productdetails/:id"
  //               element={<ProductDetails />}
  //             />
  //             <Route exact path="/laptop/:id" element={<OrganicMehendi />} />
  //             <Route exact path="/desktop/:id" element={<NailMehendi />} />
  //             <Route
  //               exact
  //               path="/smart-watch/:id"
  //               element={<IndianMehendi />}
  //             />
  //             <Route path="monitor/:id" element={<OrganicHennaPowder />} />
  //             {/* <Route path="/mehendi-review/:id" element={<MehendiReview />} /> */}
  //             <Route path="/accessories/:id" element={<MehendiArt />} />
  //             <Route path="/subcategory" element={<SubCategoryPage />} />
  //             <Route path="/subcategory/:id" element={<SubCategoryPage />} />
  //             <Route path="/categorypage" element={<CategoryPage />} />
  //             <Route path="/categorypage/:id" element={<CategoryPage />} />
  //             <Route path="/landingpage/:id" element={<LandingPage />} />
  //             <Route path="/addtocart" element={<AddToCart />} />
  //             <Route
  //               path="/checkout"
  //               element={data?.length > 0 ? <Checkout /> : <NotFound />}
  //             />
  //             <Route path="/successpage" element={<SuccessPage />} />
  //             {footerData.map((footer) => (
  //               <>
  //                 <Route path={`/${footer.slug}/:id`} element={<Pages />} />
  //               </>
  //             ))}
  //           </Routes>

  //           <button
  //             onClick={handleAddToCart}
  //             className="fixed top-[45%] right-0 z-[9999]"
  //           >
  //             <CartButton data={data} cartTotalAmount={cartTotalAmount} />
  //           </button>
  //           {state && <AddToCartCom state={state} setState={setState} />}
  //         </ScrollToTop>
  //       </div>

  //       <MobileNav handleMenu={handleMenu} />
  //       <Footer />
  //     </div>
  //   </>
  // );
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
