import { useSelector } from "react-redux";
import CategoryCard from "../components/CategoryCard";
// import ProductCard from "../components/ProductCard";

import BannerSlider from "./BannerSlider";
import BestSelling from "./BestSelling";
import FeaturedProduct from "./FeaturedProduct";
import NewArrival from "./NewArrival";
import TrendingProduct from "./TrendingProduct";
import CategoryProductSection from "../components/CategoryProductSection";
// import ReviewCardPage from "./ReviewCardPage";

const Home = () => {
  const { items: productData } = useSelector((state) => state.products);

  const { items: categoryData } = useSelector((state) => state.category);

  const combinedData = categoryData?.map((category) => ({
    ...category,
    products: productData?.filter(
      (product) => +product?.category_id === category?.id
    ),
  }));
  return (
    <div className="p-4 md:p-0">
      <BannerSlider />
      <CategoryCard />
      <TrendingProduct />
      <BestSelling />
      {/* <BannerToow /> */}
      {/* <ProductCard /> */}
      <NewArrival />
      {/* <Banner /> */}
      <FeaturedProduct />
      <CategoryProductSection combinedData={combinedData} />
      {/* {<ReviewCardPage categoryProduct={categoryProduct} />} */}
    </div>
  );
};

export default Home;
