import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { currencyFormatter } from "../utils/currencyFormatter";

import Spinner from "@material-tailwind/react/components/Spinner";
import { toast } from "react-toastify";

import { Parser } from "html-to-react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { thumbImageFetching } from "../features/products/thumbImageSlice";
import "swiper/css";
import { singleLandingPageFetching } from "../features/landingPage/singleLandingPageSlice";
import { shippingChargeFetching } from "../features/shipping/shipingCharge";
import { thumbImageFetching } from "../features/products/thambImageSlice";
const LandingPage = () => {
  const htmlParser = new Parser();
  const { id: singleLandingPageId } = useParams();
  const dispatch = useDispatch();
  const { items: singleLandingPageData } = useSelector(
    (state) => state.singleLandingPage
  );

  const { items: shippingCharge } = useSelector(
    (state) => state.shippingCharge
  );

  const { items: thumbImage } = useSelector((state) => state.thumbImage);

  useEffect(() => {
    dispatch(shippingChargeFetching());
  }, [dispatch]);

  useEffect(() => {
    dispatch(thumbImageFetching(singleLandingPageData.id));
  }, [dispatch, singleLandingPageData.id]);

  useEffect(() => {
    dispatch(singleLandingPageFetching(singleLandingPageId));
  }, [dispatch, singleLandingPageId]);

  //order information
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shipping_cost: "",
    shipping_type: "Cash On Delivery",
  });

  const grand_total =
    singleLandingPageData?.unit_price * singleLandingPageData?.cartQuantity +
    +orderData.shipping_cost;
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const res = await fetch(`${process.env.REACT_APP_URL}/place-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: orderData.name,
          // email: orderData.email,
          phone: orderData.phone,
          address: orderData.address,
          item: [singleLandingPageData],
          shipping_cost: orderData.shipping_cost,
          shipping_type: orderData.shipping_type,
          grand_total,
        }),
      });

      if (!res.ok) {
        toast.info("Quantity Increased", {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      if (res.ok) {
        setIsLoading(false);
        navigate("/successpage");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err.message);
    }
  };

  return (
    <div className="flex flex-col gap-5 my-5">
      <div className="flex flex-col gap-5 mb-5">
        <div className="container  mx-auto flex flex-col gap-10 items-center justify-center">
          <h1 className="text-4xl bg-black px-5 py-3 rounded-md text-white font-semibold">
            {singleLandingPageData.name}
          </h1>

          <h3 className="text-4xl text-black ">
            👇 অর্ডার করার আগে প্রোডাক্ট সম্পর্কে ডিটেইলস জানতে সম্পূর্ণ ভিডিওটি
            দেখুন 👇
          </h3>
          <div className=" relative w-full h-[25rem] md:h-[35rem] overflow-hidden  border-2">
            <iframe
              className="w-full h-full "
              src={`https://www.youtube.com/embed/${
                singleLandingPageData?.video_link?.split("=")[1]
              }`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div className="">
            <a
              href="#order"
              className="bg-black px-5 py-3 text-3xl  inline-block shadow-red-100 shadow-2xl border-2 border-black rounded-lg overflow-hidden  text-white hover:bg-[#662D00] duration-300"
            >
              অর্ডার করতে চাই
            </a>
          </div>
        </div>

        <div className="container mx-auto border-4 border-[#000] py-3 px-4 flex flex-col gap-10">
          {/* slider */}
          <div className="h-[30rem] w-full">
            <Swiper
              grabCursor={true}
              speed={750}
              loop={true}
              navigation={true}
              className="mySwiper h-full"
            >
              {thumbImage?.map((item) => (
                <SwiperSlide key={item?.id}>
                  <div className="w-full h-full overflow-hidden">
                    <img
                      src={`${process.env.REACT_APP_URL}/uploads/product/${item?.icon}`}
                      alt="banner pic"
                      className="w-full h-full object-contain"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="flex items-center justify-center rounded-lg overflow-hidden">
            <a
              href="#order"
              className="bg-black px-5 py-3 text-3xl  inline-block shadow-red-100 shadow-2xl border-2 border-black rounded-lg overflow-hidden  text-white hover:bg-[#662D00] duration-300"
            >
              অর্ডার করতে চাই
            </a>
          </div>
        </div>

        <div
          id="order"
          className="container mx-auto border-4 rounded-md border-black py-3 px-4 flex flex-col gap-10"
        >
          <div className="bg-white shadow-2xl rounded-2xl overflow-hidden px-10 py-5 lg:py-10">
            {htmlParser.parse(singleLandingPageData.description)}
          </div>
        </div>

        <div className="container mx-auto border-4 rounded-md border-black py-6 px-4 grid lg:grid-cols-[45%_50%] gap-10 overflow-hidden">
          <form onSubmit={handleFormSubmit}>
            <div className="billing-address flex flex-col order-2 md:order-1">
              <h2 className="text-2xl mt-5 md:mt-0 mb-5">Billing Address</h2>
              <div className="form-control flex flex-col gap-2  mb-3">
                <label className="mb-2">আপনার নাম</label>
                <input
                  required
                  type="text"
                  onChange={(e) =>
                    setOrderData({ ...orderData, name: e.target.value })
                  }
                  value={orderData.name}
                  className="px-3 py-2  rounded-md outline-none border focus:border-black duration-300"
                  placeholder="Enter Your First Name"
                />
              </div>
              <div className="form-control flex flex-col mb-3">
                <label className="mb-2">মোবাইল নাম্বার</label>
                <input
                  required
                  type="tel"
                  onChange={(e) =>
                    setOrderData({ ...orderData, phone: e.target.value })
                  }
                  value={orderData.phone}
                  className="px-3 py-2 w-full rounded-md outline-none border focus:border-black duration-300"
                  placeholder="Enter Your Number"
                />
              </div>
              {/* <div className="form-control flex flex-col mb-3">
                <label className="mb-2">আপনার ইমেল</label>
                <input
                  className="px-3 py-2  rounded-md outline-none border focus:border-[#EF6536] duration-300"
                  onChange={(e) =>
                    setOrderData({ ...orderData, email: e.target.value })
                  }
                  value={orderData.email}
                  type="email"
                  placeholder="Enter Your Email..."
                />
              </div> */}

              <div className="form-control flex flex-col  mb-3 ">
                <label className="mb-2">আপনাকে ঠিকানা</label>
                <input
                  required
                  type="text"
                  onChange={(e) =>
                    setOrderData({ ...orderData, address: e.target.value })
                  }
                  value={orderData.address}
                  className="px-3 py-2 w-full rounded-md outline-none border focus:border-black duration-300 resize-none"
                  placeholder="Enter Your Address"
                />
              </div>

              <div className="form-control flex flex-col gap-2  mb-3">
                <select
                  required
                  onChange={(e) =>
                    setOrderData({
                      ...orderData,
                      shipping_cost: e.target.value,
                    })
                  }
                  className="px-3 py-2  rounded-md outline-none border focus:border-black duration-300"
                >
                  <option value="">Select Area</option>
                  {shippingCharge?.map((charge) => (
                    <option key={charge.id} value={`${charge?.amount}`}>
                      {charge?.name}({charge?.amount})
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-control flex items-end justify-between  ">
                <div className="flex">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className={`${
                      isLoading
                        ? " bg-gray-600 "
                        : "bg-black hover:bg-[#292929]"
                    } px-3 py-3 rounded-md text-lg text-white bg   duration-300`}
                  >
                    {isLoading ? <Spinner /> : "Confirm Your Order"}
                  </button>
                </div>
              </div>
            </div>
          </form>

          <div className="order-details">
            <h2 className="text-2xl">Order Details</h2>

            <div className="h-auto overflow-y-scroll order-details-container">
              <div
                // key={product.id}
                className="cart-items  h-20  flex  items-start gap-4 mt-3 overflow-hidden"
              >
                <div className="cart-img aspect-square w-20 flex justify-center items-center overflow-hidden ">
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/product/${singleLandingPageData?.photos}`}
                    className="w-full object-cover "
                    alt=""
                  />
                </div>
                <div className="cart-description w-full overflow-hidden ">
                  <div className="flex flex-col">
                    <p className="truncate">{singleLandingPageData?.name}</p>
                    <div className="cart-price flex gap-2">
                      <span>
                        {currencyFormatter(singleLandingPageData?.unit_price)}
                      </span>
                      x<span>{singleLandingPageData?.cartQuantity}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shipping_Type  flex  md:flex-col lg:flex-row gap-5 ">
              <div>
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    checked
                    // onChange={(e) =>
                    //   setOrderData({
                    //     ...orderData,
                    //     shipping_type: e.target.value,
                    //   })
                    // }
                    name="shipping_type"
                    value={"Cash On Delivery"}
                    className="peer sr-only"
                  />
                  <div className="w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                    <img
                      src="/cash_on_delivery.jpg"
                      alt="cash_on_delivery"
                      className="w-full h-12 "
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="payment-summary flex flex-col gap-3 mt-5 ">
              <div className="sub-total flex justify-between">
                <h4 className="text-lg text-slate-400 font-semibold">
                  Sub Total
                </h4>
                <span className="text-slate-700 ">
                  {currencyFormatter(
                    singleLandingPageData?.unit_price *
                      singleLandingPageData?.cartQuantity
                  )}
                </span>
              </div>
              <div className="shipping flex justify-between">
                <h4 className="text-lg text-slate-400 font-semibold ">
                  Shipping
                </h4>
                <span className="text-slate-700 ">
                  {currencyFormatter(+orderData.shipping_cost)}
                </span>
              </div>
              {/* <div className="shipping flex justify-between">
                <h4 className="text-lg text-slate-400 font-semibold ">
                  Discount
                </h4>
                <span className="text-slate-700 "></span>
              </div> */}
              <div className="sub-total flex justify-between">
                <h4 className="text-lg text-slate-400 font-semibold">Total</h4>
                <span className="text-slate-700 font-semibold ">
                  {currencyFormatter(grand_total)}
                </span>
              </div>
              <div>
                {/* <div className="flex   gap-3">
                      <input
                        type="text"
                        value={couponCode}
                        onChange={handleCouponCodeChange}
                        className="px-3 py-2 xl:w-72  w-60  rounded-md outline-none border"
                      />
                      <button
                        onClick={applyCoupon}
                        className="bg-[#0ea5e9] px-3  text-sm rounded-md"
                      >
                        Apply Coupon
                      </button>
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
